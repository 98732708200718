import React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"

const Button = loadable(() => import("../Button"))

const Venue = ({ register, errors }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  const errorMessage = errors => {
    if (language == "en") {
      if (errors.email?.type === "pattern") {
        return "Please enter a valid email address."
      }
      if (errors.date?.type === "pattern") {
        return "Please ensure you enter a valid date format, DD/MM/YYYY."
      }
      return "Please answer all required fields."
    } else {
      if (errors.email?.type === "pattern") {
        return "Si prega di inserire un indirizzo email valido."
      }
      if (errors.date?.type === "pattern") {
        return "Si prega di inserire una data valida GG/MM/AAAA."
      }
      return "Si prega di rispondere a tutti i campi obbligatori."
    }
  }

  return (
    <>
      <input
        type="hidden"
        name="subject"
        value="Venue Enquiry"
        data-remove-prefix
        {...register("subject")}
      />
      <div className="formField">
        <label htmlFor="name">
          <input
            type="text"
            name="name"
            id="name"
            placeholder=" "
            className={errors?.name && "is-invalid"}
            aria-invalid={errors?.name ? "true" : "false"}
            {...register("name", { required: true })}
          />
          <span>
            {language == "en" ? (
              <>
                Your name <span>(required)</span>
              </>
            ) : (
              <>
                Il tuo nome <span>(necessario)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="formField">
        <label htmlFor="email">
          <input
            type="text"
            name="email"
            id="email"
            placeholder=" "
            className={errors?.email && "is-invalid"}
            aria-invalid={errors?.email ? "true" : "false"}
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          <span>
            {language == "en" ? (
              <>
                Your e-mail <span>(required)</span>
              </>
            ) : (
              <>
                La tua email <span>(necessario)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="formField">
        <label htmlFor="phone">
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder=" "
            className={errors?.phone && "is-invalid"}
            aria-invalid={errors?.phone ? "true" : "false"}
            {...register("phone", { required: true })}
          />
          <span>
            {language == "en" ? (
              <>
                Your phone <span>(required, only used for this enquiry)</span>
              </>
            ) : (
              <>
                Il tuo telefono{" "}
                <span>(richiesto, utilizzato solo per questa richiesta)</span>
              </>
            )}
          </span>
        </label>
      </div>
      {/* <div className="formField">
        <label htmlFor="date">
          <input
            type="text"
            name="date"
            id="date"
            placeholder=" "
            className={`date-field ${errors?.date && 'is-invalid'}`}
            aria-invalid={errors?.date ? 'true' : 'false'}
            {...register('date', {
              required: true,
              pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
            })}
          />
          <span>
            {language == 'en' ? <>
              Preferred start date <span>(required, DD/MM/YYYY)</span>
            </> : <>
              Data di inizio preferita <span>(necessario, GG/MM/AAAA)</span>
            </>}
          </span>
        </label>
      </div> */}
      <div className="formField formTextarea">
        <label htmlFor="message">
          <textarea
            name="message"
            id="message"
            placeholder=" "
            className={errors?.message && "is-invalid"}
            aria-invalid={errors?.message ? "true" : "false"}
            {...register("message", { required: true })}
          />
          <span>
            {language == "en" ? (
              <>
                Questions or remarks <span>(required)</span>
              </>
            ) : (
              <>
                Domande o osservazioni <span>(necessario)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="mt-4 text-center">
        <Button type="submit" colour="green" full>
          {language == "en" ? <>Send</> : <>Invia</>}
        </Button>
        {errors?.name ||
        errors?.email ||
        errors?.phone ||
        errors?.date ||
        errors?.message ? (
          <p className="mt-2 text-sm text-red-500" role="alert">
            {errorMessage(errors)}
          </p>
        ) : (
          <p className="mt-2 text-sm">
            {language == "en" ? (
              <>We answer within 24 hours. No obligations.</>
            ) : (
              <>Rispondiamo entro 24 ore. Nessun obbligo.</>
            )}
          </p>
        )}
      </div>
    </>
  )
}

export default Venue

Venue.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
}
